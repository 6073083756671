import React from 'react';
import { FingoPaper } from '@fingo/lib/components/layout';
import { hasShowSubRoutes, userHasRoutePermission } from '@fingo/lib/helpers/routes';
import SendOutlined from '@mui/icons-material/SendOutlined';
import { PricingCampaigns } from '../components';
import PricingRules from '../components/pricing-engine/PricingRules';
import PricingBusinessRates from '../components/pricing-engine/PricingBusinessRates';

export const pricingCampaignsTabs = (user) => [
  {
    id: 'campaigns',
    label: 'Campañas',
    path: 'campaigns',
    component: <PricingCampaigns />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'marketing_campaign.view_pricingcampaign'),
  },
];

export const pricingEngineTabs = (user) => [
  {
    id: 'rules',
    label: 'Reglas',
    path: 'rules',
    component: <PricingRules />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'marketing_campaign.view_pricingcampaign'),
  },
  {
    id: 'business-rates',
    label: 'Tasas',
    path: 'business-rates',
    component: <PricingBusinessRates />,
    disabled: false,
    showInSidebar: true,
    show: userHasRoutePermission(user, 'marketing_campaign.view_pricingcampaign'),
  },
];

export const pricingRoutes = (user) => [
  {
    id: 'pricing-campaigns',
    label: 'Campañas pricing',
    path: 'pricing-campaigns',
    icon: <SendOutlined />,
    component: <FingoPaper tabs={pricingCampaignsTabs(user)} />,
    disabled: false,
    showInSidebar: true,
    show: hasShowSubRoutes(user, pricingCampaignsTabs(user)),
  },
  {
    id: 'pricing-engine',
    label: 'Motor',
    path: 'pricing-engine',
    icon: <SendOutlined />,
    component: <FingoPaper tabs={pricingEngineTabs(user)} />,
    disabled: false,
    showInSidebar: true,
    show: hasShowSubRoutes(user, pricingEngineTabs(user)),
  },
];

export default pricingRoutes;
