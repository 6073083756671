import React from 'react';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import PricingRulesActions from './PricingRulesActions';
import { PRICING_RULES } from '../../graphql/pricing-engine/queries';
import { PRICING_RULE_COLUMNS } from '../../constants/pricing-engine';

const PricingRules = () => (
  <FingoMainView
    id="pricing-rules"
    query={PRICING_RULES}
    slots={{
      header: PaperHeader,
      actions: PricingRulesActions,
      table: DataGrid,
    }}
    slotProps={{
      header: {
        viewTitle: 'Reglas',
      },
      table: {
        columns: PRICING_RULE_COLUMNS,
        disableSelectionOnClick: true,
        density: 'compact',
        noRowsMessage: () => <Typography>No hay reglas.</Typography>,
        initialOrderBy: 'minimumAmount',
        paginationMode: 'server',
        rowsPerPageOptions: [15, 25, 50, 100],
      },
    }}
  />
);

export default PricingRules;
