import React, { useState } from 'react';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoDataGrid from '@fingo/lib/components/dataGrids/FingoDataGrid';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import Typography from '@mui/material/Typography';
import { HEADER_TITLE, COLUMNS } from '../constants/pricing-campaigns';
import { PRICING_CAMPAIGNS } from '../graphql/pricing-campaigns/queries';
import PricingCampaignActions from './pricing-campaigns/PricingCampaignsActions';

const PricingCampaigns = () => {
  const [selectedPricingCampaignIds, setSelectedPricingCampaignIds] = useState(
    [],
  );

  return (
    <FingoMainView
      id="pricing-campaigns"
      query={PRICING_CAMPAIGNS}
      slots={{
        header: PaperHeader,
        actions: PricingCampaignActions,
        table: FingoDataGrid,
      }}
      slotProps={{
        header: { viewTitle: HEADER_TITLE },
        actions: { selectedPricingCampaignIds },
        table: {
          columns: COLUMNS,
          disableSelectionOnClick: true,
          checkboxSelection: true,
          onSelectionModelChange: (ids) => {
            setSelectedPricingCampaignIds(ids);
          },
          selectionModel: selectedPricingCampaignIds,
          noRowsMessage: () => (
            <Typography variant="body2">No hay campañas pricing</Typography>
          ),
          density: 'compact',
          initialOrderBy: 'id',
          paginationMode: 'server',
          rowsPerPageOptions: [15, 25, 50, 100],
        },
      }}
    />
  );
};

export default PricingCampaigns;
