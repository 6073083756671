import { useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useSnackBars } from '@fingo/lib/hooks';
import { NEW_CAMPAIGN_INITIAL_STATE } from '../constants/pricing-campaigns';
import { CREATE_PRICING_CAMPAIGN } from '../graphql/pricing-campaigns/mutations';
import { PRICING_CAMPAIGNS } from '../graphql/pricing-campaigns/queries';

const useCreatePricingCampaign = () => {
  const { addAlert } = useSnackBars();
  const [campaign, setCampaign] = useState(NEW_CAMPAIGN_INITIAL_STATE);

  const [createPricingCampaign] = useMutation(CREATE_PRICING_CAMPAIGN, {
    variables: {
      pricingCampaign: {
        ...campaign,
        startDate: campaign.startDate?.format('YYYY-MM-DD') || null,
        endDate: campaign.endDate?.format('YYYY-MM-DD') || null,
        rateModificationValue: parseFloat(
          campaign.rateModificationValue.replace(',', '.'),
        ),
        minRelationshipMetricDays: parseInt(
          campaign.RelationshipMetricDaysRange?.split('-')[0],
          10,
        ),
        maxRelationshipMetricDays: parseInt(
          campaign.RelationshipMetricDaysRange?.split('-')[1],
          10,
        ),
        hunting: campaign.hunting || null,
        farming: campaign.farming || null,
        expireAfterOperation: campaign.expireAfterOperation ?? false,
        companiesFile: campaign.companiesFile,
      },
    },
    onCompleted: () => {
      addAlert({
        id: 'create-pricing-campaign',
        message: 'Se creó correctamente la campaña',
      });
    },
    onError: ({ message }) => {
      addAlert({
        id: 'create-pricing-campaign-error',
        message: `Error: ${message}`,
      });
    },
    refetchQueries: [PRICING_CAMPAIGNS],
  });

  const {
    startDate,
    endDate,
    priority,
    rateModificationType,
    rateModificationValue,
  } = campaign;

  const disabled = useMemo(
    () => !startDate
      || !endDate
      || !priority
      || !rateModificationType
      || !rateModificationValue,
    [startDate, endDate, priority, rateModificationType, rateModificationValue],
  );

  return { campaign, setCampaign, createPricingCampaign, disabled };
};

export default useCreatePricingCampaign;
