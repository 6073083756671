import React from 'react';
import { userHasRoutePermission } from '@fingo/lib/helpers/routes';
import FundsSwitch from '../components/FundsSwitch';

const FundsDownloadTabs = (user) => [
  {
    id: 'funds-rosters',
    label: 'Nóminas de Descarga',
    path: 'details',
    component: <FundsSwitch />,
    disabled: false,
    show: userHasRoutePermission(user, 'funding.view_invoiceloanroster'),
  },
];

export default FundsDownloadTabs;
