import { gql } from '@apollo/client';
import { PricingCampaignFields } from './fragments';

export const PRICING_CAMPAIGNS = gql`
  query pricingCampaigns(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
    $orderBy: String
    $id: ID
    $id_In: [String]
  ) {
    pricingCampaigns(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
      orderBy: $orderBy
      id: $id
      id_In: $id_In
    ) {
      totalCount
      edges {
        node {
          ...PricingCampaignFields
        }
      }
    }
  }
  ${PricingCampaignFields}
`;

export const PRICING_CAMPAIGN_COMPANIES = gql`
  query PricingCampaignCompanies(
    $id: ID!
    $before: String
    $after: String
    $first: Int
    $offset: Int
    $last: Int
  ) {
    pricingCampaign(id: $id) {
      id
      companies(
        first: $first
        before: $before
        offset: $offset
        after: $after
        last: $last
      ) {
        totalCount
        edges {
          node {
            id
            masterEntity {
              id
              name
              displayNationalIdentifier
            }
          }
        }
      }
    }
  }
`;
