import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormControlLabel,
  Grid,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { prettifyRut, removeSeparators } from 'react-rut-formatter';
import { ControlledDatePicker } from '@fingo/lib/components/datePickers';
import UploadFileMiniInput from '@fingo/lib/components/inputs/UploadFileMiniInput';
import {
  ControlledCheckboxInput,
  ControlledNumberInput,
} from '@fingo/lib/components/inputs';
import {
  ControlledMultipleSelect,
  ControlledSelect,
} from '@fingo/lib/components/selects';
import Publish from '@mui/icons-material/Publish';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import {
  RATE_MODIFICATION_TYPES,
  SII_SALES_SECTION_OPTIONS,
  RELATIONSHIP_METRIC_DAYS_OPTIONS,
} from '../../constants/pricing-campaigns';
import useCreatePricingCampaign from '../../hooks/useCreatePricingCampaign';

const CreatePricingCampaignForm = ({ handleClose }) => {
  const { campaign, setCampaign, disabled, createPricingCampaign } = useCreatePricingCampaign();

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={3}>
        <ControlledDatePicker
          value={campaign.startDate}
          onChange={(newStartDate) => setCampaign({ ...campaign, startDate: newStartDate })}
          label="Fecha de inicio"
          disablePast
        />
      </Grid>
      <Grid item xs={3}>
        <ControlledDatePicker
          value={campaign.endDate}
          onChange={(newEndDate) => setCampaign({ ...campaign, endDate: newEndDate })}
          label="Fecha de término"
          shouldDisableDate={(date) => campaign.startDate && date <= campaign.startDate}
        />
      </Grid>
      <Grid item xs={3}>
        <ControlledMultipleSelect
          id="client-sii-sales-sections"
          value={campaign.clientSiiSalesSections}
          onChange={(newClientSiiSalesSections) => setCampaign({
            ...campaign,
            clientSiiSalesSections: newClientSiiSalesSections,
            companiesFile: null,
          })}
          label="Tramo cliente"
          options={SII_SALES_SECTION_OPTIONS}
        />
      </Grid>
      <Grid item xs={3}>
        <ControlledMultipleSelect
          id="debtor-sii-sales-sections"
          value={campaign.debtorSiiSalesSections}
          onChange={(newDebtorSiiSalesSections) => {
            setCampaign({
              ...campaign,
              debtorSiiSalesSections: newDebtorSiiSalesSections,
              debtor: null,
            });
          }}
          label="Tramo deudor"
          options={SII_SALES_SECTION_OPTIONS}
        />
      </Grid>
      <Grid item xs={3}>
        <ControlledSelect
          value={campaign.relationshipMetricDaysRange}
          handleChange={(e) => {
            setCampaign({
              ...campaign,
              relationshipMetricDaysRange: e.target.value,
            });
          }}
          label="Días relación cliente - deudor"
          options={RELATIONSHIP_METRIC_DAYS_OPTIONS}
        />
      </Grid>
      <Grid item xs={3} sx={{ px: '50px !important' }}>
        <ControlledCheckboxInput
          value={campaign.hunting}
          onChange={(event) => setCampaign({ ...campaign, hunting: event.target.checked })}
          label="Hunting"
        />
      </Grid>
      <Grid item xs={3} sx={{ px: '50px !important' }}>
        <ControlledCheckboxInput
          value={campaign.farming}
          onChange={(event) => setCampaign({ ...campaign, farming: event.target.checked })}
          label="Farming"
        />
      </Grid>
      <Grid item xs={3}>
        <ControlledNumberInput
          value={campaign.priority}
          onChange={(e) => setCampaign({ ...campaign, priority: e.target.value })}
          label="Prioridad"
        />
      </Grid>
      <Grid item xs={3}>
        <Stack direction="column">
          <Typography variant="formLabel">Deudor en específico:</Typography>
          <OutlinedInput
            value={
              campaign.debtor !== '0' || '' ? prettifyRut(campaign.debtor) : ''
            }
            onChange={(e) => {
              const { value } = e.target;
              setCampaign({
                ...campaign,
                debtor:
                  value.length > 0 && value !== '0-'
                    ? removeSeparators(value)
                    : null,
                debtorSiiSalesSections: [],
              });
            }}
            variant="form"
            placeholder="RUT sin puntos ni dv"
          />
        </Stack>
      </Grid>
      <Grid item xs={9}>
        <UploadFileMiniInput
          onChange={(e) => {
            const { files } = e.target;
            setCampaign({
              ...campaign,
              companiesFile: files[0],
              clientSiiSalesSections: [],
            });
          }}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          fullWidth
          placeHolder={
            campaign.companiesFile !== null
              ? 'Archvivo subido'
              : 'Subir listado de IDs'
          }
          variant="outlined"
          component="span"
          endIcon={
            campaign.companiesFile !== null ? (
              <CheckCircleOutline color="primary" />
            ) : (
              <Publish color="primary" />
            )
          }
        />
      </Grid>
      <Grid item xs={12}>
        <RadioGroup
          row
          value={campaign.rateModificationType}
          onChange={(e) => {
            setCampaign({
              ...campaign,
              rateModificationType: e.target.value,
              rateModificationValue: '',
            });
          }}
        >
          {RATE_MODIFICATION_TYPES.map(
            ({ endAdornment, label, startAdornment, value }) => (
              <Stack key={label} alignItems="center">
                <FormControlLabel
                  value={value}
                  control={<Radio />}
                  label={label}
                />
                <OutlinedInput
                  value={
                    campaign.rateModificationType === value
                      ? campaign.rateModificationValue
                      : ''
                  }
                  disabled={campaign.rateModificationType !== value}
                  endAdornment={endAdornment}
                  startAdornment={startAdornment}
                  onChange={(e) => setCampaign({
                    ...campaign,
                    rateModificationValue: e.target.value,
                  })}
                  variant="form"
                  sx={{
                    width: 70,
                    maxHeight: 10,
                    pl: '2px',
                    '& .MuiOutlinedInput-input': { p: 0.5, pr: 0 },
                  }}
                />
              </Stack>
            ),
          )}
        </RadioGroup>
        <ControlledCheckboxInput
          value={campaign.expireAfterOperation}
          onChange={(event) => setCampaign(
            { ...campaign, expireAfterOperation: event.target.checked },
          )}
          label="Expira tras primera operación"
        />
      </Grid>
      <Grid item xs={12}>
        <Stack direction="column">
          <Typography alignSelf="center" variant="h6">
            ¿Confirmar creación de campaña?
          </Typography>
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Button onClick={handleClose}>Cancelar</Button>
            <Button
              onClick={() => {
                createPricingCampaign({ onCompleted: handleClose });
              }}
              disabled={disabled}
            >
              Confirmar
            </Button>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

CreatePricingCampaignForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default CreatePricingCampaignForm;
