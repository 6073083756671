import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { PRICING_CAMPAIGN_COMPANIES } from '../graphql/pricing-campaigns/queries';

const usePricingCampaignCompanies = (pricingCampaignId, { first, offset }) => {
  const { data, loading } = useQuery(PRICING_CAMPAIGN_COMPANIES, {
    variables: { id: pricingCampaignId, first, offset },
  });

  const companies = useMemo(
    () => data?.pricingCampaign?.companies.edges.map((edge) => edge.node),
    [data],
  );

  const totalCount = useMemo(
    () => data?.pricingCampaign?.companies.totalCount || 0,
    [data],
  );

  return { companies, totalCount, loading };
};

export default usePricingCampaignCompanies;
