import React from 'react';
import Remove from '@mui/icons-material/Remove';
import Clear from '@mui/icons-material/Clear';
import Percent from '@mui/icons-material/Percent';
import { BooleanCell } from '@fingo/lib/components/cells';
import PricingCampaignCompanies from '../components/pricing-campaigns/PricingCampaignCompanies';

export const PRICING_CAMPAIGN_STATUS_MAPPER = {
  CREATED: 'Creada',
  PROCESSING: 'Por activar',
  ACTIVE: 'Activa',
  DEACTIVATED: 'Expirada',
};

export const RATE_MODIFICATION_TYPE_MAPPER = {
  FIXED: 'Tasa fija',
  MULTIPLIER: 'Ponderador',
  SUBTRACTION: 'Resta',
};

export const COLUMNS = [
  {
    field: 'id',
    headerName: 'ID',
    disableColumnMenu: true,
    width: 50,
  },
  {
    field: 'startDate',
    type: 'date',
    headerName: 'Fecha de inicio',
    disableColumnMenu: true,
    width: 135,
  },
  {
    field: 'endDate',
    type: 'date',
    headerName: 'Fecha de término',
    disableColumnMenu: true,
    width: 150,
  },
  {
    field: 'clientSiiSalesSection_Value',
    headerName: 'Tramos SII cliente',
    valueGetter: ({ row }) => {
      const siiSalesSections = row.clientSiiSalesSections.edges?.map(
        ({ node }) => node,
      );
      return siiSalesSections.map((dsss) => dsss.value).join(', ');
    },
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    valueFormatter: ({ value }) => value || '-',
  },
  {
    field: 'debtorSiiSalesSection_Value',
    headerName: 'Tramos SII deudor',
    valueGetter: ({ row }) => {
      const siiSalesSections = row.debtorSiiSalesSections.edges?.map(
        ({ node }) => node,
      );
      return siiSalesSections.map((dsss) => dsss.value).join(', ');
    },
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    valueFormatter: ({ value }) => value || '-',
  },
  {
    field: 'relationshipMetricDaysRange',
    headerName: 'Días de relación',
    width: 110,
    sortable: false,
    disableColumnMenu: true,
    valueGetter: ({ row }) => {
      if (
        typeof row.minRelationshipMetricDays === 'number'
        && typeof row.maxRelationshipMetricDays === 'number'
      ) {
        return `${row.minRelationshipMetricDays} - ${row.maxRelationshipMetricDays}`;
      }
      return '-';
    },
  },
  {
    field: 'farming',
    type: 'boolean',
    headerName: 'Farming',
    width: 95,
    disableColumnMenu: true,
    renderCell: ({ row }) => <BooleanCell value={row.farming} />,
  },
  {
    field: 'hunting',
    type: 'boolean',
    headerName: 'Hunting',
    width: 95,
    disableColumnMenu: true,
    renderCell: ({ row }) => <BooleanCell value={row.hunting} />,
  },
  {
    field: 'priority',
    headerName: 'Prioridad',
    width: 100,
    disableColumnMenu: true,
  },
  {
    field: 'rateModificationType',
    headerName: 'Modificador',
    width: 90,
    sortable: false,
    disableColumnMenu: true,
    valueFormatter: ({ value }) => RATE_MODIFICATION_TYPE_MAPPER[value],
  },
  {
    field: 'rateModificationValue',
    headerName: 'Valor',
    width: 50,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'author',
    headerName: 'Creada por',
    valueGetter: ({ row }) => row.author.completeName,
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'status',
    headerName: 'Estado',
    valueFormatter: ({ value }) => PRICING_CAMPAIGN_STATUS_MAPPER[value],
    width: 85,
    disableColumnMenu: true,
  },
  {
    field: 'companies',
    headerName: 'Ver empresas',
    renderCell: ({ row }) => (
      <PricingCampaignCompanies pricingCampaignId={row.id} />
    ),
    width: 100,
    align: 'center',
    sortable: false,
    disableColumnMenu: true,
  },
];

export const NEW_CAMPAIGN_INITIAL_STATE = {
  startDate: null,
  endDate: null,
  clientSiiSalesSections: [],
  debtorSiiSalesSections: [],
  relationshipMetricDaysRange: null,
  hunting: false,
  farming: false,
  priority: 0,
  debtor: null,
  rateModificationType: '',
  rateModificationValue: '',
  companiesFile: null,
  expireAfterOperation: false,
};

export const HEADER_TITLE = 'Campañas Pricing';

export const RATE_MODIFICATION_TYPES = [
  {
    endAdornment: <Percent fontSize="15px" />,
    label: 'Tasa fija',
    value: 'Fixed',
  },
  {
    startAdornment: <Clear fontSize="15px" />,
    label: 'Multiplicador',
    value: 'Multiplier',
  },
  {
    startAdornment: <Remove fontSize="15px" />,
    label: 'Resta',
    value: 'Subtraction',
  },
];

export const SII_SALES_SECTION_OPTIONS = Array.from(
  { length: 13 },
  (_, i) => i + 1,
).map((number) => ({
  label: number.toString(),
  value: number,
  disabled: false,
}));

export const RELATIONSHIP_METRIC_DAYS_OPTIONS = [
  { label: '(No considerar)', value: null, disabled: false },
  { label: '0 - 180', value: '0-180', disabled: false },
  { label: '181 - 360', value: '181-360', disabled: false },
  { label: '361 - 540', value: '361-540', disabled: false },
  { label: '541 - 720', value: '541-720', disabled: false },
  { label: '+720', value: '720-999999', disabled: false },
];
