import React from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Info from '../components/Info';
import MyInfo from '../components/info/MyInfo';

export const profileRoutes = (selectedCompany) => [
  {
    id: 'my-info',
    label: 'Mi información',
    path: 'info',
    icon: <PersonOutlineOutlinedIcon />,
    component: <Info />,
    disabled: false,
    showInSidebar: true,
    show: !!selectedCompany,
  },
];

export const infoTabs = [
  {
    id: 'my-profile',
    label: 'Mi información',
    path: 'my-profile',
    component: <MyInfo />,
    disabled: false,
    showInSidebar: true,
    show: true,
  },
];
