import { gql } from '@apollo/client';

export const UPLOAD_PRICING_RULES = gql`
  mutation uploadPricingRules($file: Upload!) {
    uploadPricingRules(file: $file) {
      pricingRules {
        id
      }
    }
  }
`;

export const UPLOAD_PRICING_BUSINESS_RATES = gql`
  mutation uploadPricingBusinessRates($file: Upload!) {
    uploadPricingBusinessRates(file: $file) {
      pricingBusinessRates {
        id
      }
    }
  }
`;
