import { gql } from '@apollo/client';

export const CREATE_PRICING_CAMPAIGN = gql`
  mutation createPricingCampaign($pricingCampaign: PricingCampaignInputType!) {
    createPricingCampaign(pricingCampaign: $pricingCampaign) {
      pricingCampaign {
        id
      }
    }
  }
`;

export const PROCESS_PRICING_CAMPAIGNS = gql`
  mutation processPricingCampaign($pricingCampaignIds: [ID]!) {
    processPricingCampaigns(pricingCampaignIds: $pricingCampaignIds) {
      pricingCampaigns {
        id
        status
      }
    }
  }
`;

export const DEACTIVATE_PRICING_CAMPAIGNS = gql`
  mutation deactivatePricingCampaigns($pricingCampaignIds: [ID]!) {
    deactivatePricingCampaigns(pricingCampaignIds: $pricingCampaignIds) {
      pricingCampaigns {
        id
        status
      }
    }
  }
`;
