import { gql } from '@apollo/client';
import { PricingBusinessRateFields, PricingRuleFields } from './fragments';

export const PRICING_BUSINESS_RATES = gql`
  query pricingBusinessRates(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    pricingBusinessRates(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      totalCount
      edges {
        node {
          ...PricingBusinessRateFields
        }
      }
    }
  }
  ${PricingBusinessRateFields}
`;

export const PRICING_RULES = gql`
  query pricingRules(
    $offset: Int
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    pricingRules(
      offset: $offset
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      totalCount
      edges {
        node {
          ...PricingRuleFields
        }
      }
    }
  }
  ${PricingRuleFields}
`;
