import { gql } from '@apollo/client';

export const PricingBusinessRateFields = gql`
  fragment PricingBusinessRateFields on PricingBusinessRateType {
    id
    clientSiiSalesSection {
      id
      value
    }
    debtorSiiSalesSection {
      id
      value
    }
    rate
    defaultRate
  }
`;

export const PricingRuleFields = gql`
  fragment PricingRuleFields on PricingRuleType {
    id
    minimumAmount {
      amount
    }
    maximumAmount {
      amount
    }
    amountMultiplier
    minimumInvoiceDuration
    maximumInvoiceDuration
    invoiceDurationMultiplier
    minimumEmitterReceiverRelationshipDays
    maximumEmitterReceiverRelationshipDays
    emitterReceiverRelationshipDaysMultiplier
    minimumRate
    maximumRate
    rateMultiplier
    active
  }
`;
