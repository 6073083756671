/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TablePagination,
  Skeleton,
} from '@mui/material';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import usePricingCampaignCompanies from '../../hooks/usePricingCampaignCompanies';

const PricingCampaignCompaniesDialog = ({
  pricingCampaignId,
  open,
  handleClose,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { companies, loading, totalCount } = usePricingCampaignCompanies(
    pricingCampaignId,
    {
      first: rowsPerPage,
      offset: page * rowsPerPage,
    },
  );

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  }, []);

  return (
    <FingoDialog
      open={open}
      handleClose={handleClose}
      maxWidth="xl"
      fullWidth
      title="Empresas de la campaña"
    >
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre de la Empresa</TableCell>
              <TableCell>RUT</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading
              ? Array.from(new Array(rowsPerPage)).map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton animation="wave" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" />
                  </TableCell>
                </TableRow>
              ))
              : companies.map((company) => (
                <TableRow key={company.id}>
                  <TableCell>{company.masterEntity.name}</TableCell>
                  <TableCell>{company.masterEntity.displayNationalIdentifier}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalCount}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </FingoDialog>
  );
};

PricingCampaignCompaniesDialog.propTypes = {
  pricingCampaignId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default PricingCampaignCompaniesDialog;
