export const PRICING_BUSINESS_RATE_COLUMNS = [
  {
    field: 'clientSiiSalesSection_Value',
    type: 'number',
    headerName: 'Tramo SII cliente',
    flex: 1,
    valueGetter: ({ row }) => row.clientSiiSalesSection.value,
  },
  {
    field: 'debtorSiiSalesSection_Value',
    type: 'number',
    headerName: 'Tramo SII deudor',
    flex: 1,
    valueGetter: ({ row }) => row.debtorSiiSalesSection.value,
  },
  {
    field: 'rate',
    type: 'number',
    headerName: 'Tasa',
    flex: 1,
  },
  {
    field: 'defaultRate',
    type: 'number',
    headerName: 'Tasa mora',
    flex: 1,
  },
];

export const PRICING_RULE_COLUMNS = [
  {
    field: 'minimumAmount',
    type: 'number',
    headerName: 'Monto mínimo',
    flex: 1,
    valueGetter: ({ row }) => row.minimumAmount.amount,
  },
  {
    field: 'maximumAmount',
    type: 'number',
    headerName: 'Monto máximo',
    flex: 1,
    valueGetter: ({ row }) => row.maximumAmount.amount,
  },
  {
    field: 'amountMultiplier',
    type: 'number',
    headerName: 'Multiplicador monto',
    flex: 1,
  },
  {
    field: 'minimumInvoiceDuration',
    type: 'number',
    headerName: 'Fecha de expiración mínima',
    flex: 1,
  },
  {
    field: 'maximumInvoiceDuration',
    type: 'number',
    headerName: 'Fecha de expiración máxima',
    flex: 1,
  },
  {
    field: 'invoiceDurationMultiplier',
    type: 'number',
    headerName: 'Multiplicador fecha de expiración',
    flex: 1,
  },
  {
    field: 'minimumEmitterReceiverRelationshipDays',
    type: 'number',
    headerName: 'Días de relación mínimo',
    flex: 1,
  },
  {
    field: 'maximumEmitterReceiverRelationshipDays',
    type: 'number',
    headerName: 'Días de relación máximo',
    flex: 1,
  },
  {
    field: 'emitterReceiverRelationshipDaysMultiplier',
    type: 'number',
    headerName: 'multiplicador días de relación',
    flex: 1,
  },
  {
    field: 'minimumRate',
    type: 'number',
    headerName: 'Tasa mínima',
    flex: 1,
  },
  {
    field: 'maximumRate',
    type: 'number',
    headerName: 'Tasa máxima',
    flex: 1,
  },
  {
    field: 'rateMultiplier',
    type: 'number',
    headerName: 'Multiplicador tasa',
    flex: 1,
  },
  {
    field: 'active',
    type: 'boolean',
    headerName: 'Activa',
    flex: 1,
  },
];
