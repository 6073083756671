import React from 'react';
import PropTypes from 'prop-types';
import FingoDialog from '@fingo/lib/components/dialogs/FingoDialog';
import CreatePricingCampaignForm from './CreatePricingCampaignForm';

const CreatePricingCampaignDialog = ({ open, handleClose }) => (
  <FingoDialog open={open} handleClose={handleClose} title="Crear campaña" fullWidth paddingContent={4}>
    <CreatePricingCampaignForm handleClose={handleClose} />
  </FingoDialog>
);

CreatePricingCampaignDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default CreatePricingCampaignDialog;
