/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const PricingCampaignFields = gql`
  fragment PricingCampaignFields on PricingCampaignType {
    id
    startDate
    endDate
    clientSiiSalesSections {
      edges {
        node {
          id
          value
        }
      }
    }
    debtorSiiSalesSections {
      edges {
        node {
          id
          value
        }
      }
    }
    minRelationshipMetricDays
    maxRelationshipMetricDays
    hunting
    farming
    priority
    debtor {
      id
    }
    rateModificationType
    rateModificationValue
    status
    author {
      firstName
      lastName
      completeName @client
    }
    expireAfterOperation
  }
`;
